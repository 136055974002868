import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Client from 'shopify-buy'

import Header from '../components/header'
import Footer from '../components/footer'

import '../assets/scss/main.scss'

class Layout extends Component {

  state = {
    cartRequest: false,
    checkout: { lineItems: [] },
  }

  componentDidMount() {
    this.client = Client.buildClient({
      domain: `seivabusiness.myshopify.com`,
      storefrontAccessToken: `1c86ac1fc0a9423533af3f2e46a8b17a`
    })
    // Create an empty checkout
    this.client.checkout.create().then((checkout) => {
      // Do something with the checkout
      this.setState({ checkout })
    })
  }

  _addVariantToCart = (variantId, quantity) => {
    this.setState({ cartRequest: true })
    variantId = variantId.split('Shopify__ProductVariant__')[1]

    const lineItemsToAdd = [{variantId, quantity: parseInt(quantity, 10)}]
    const checkoutId = this.state.checkout.id

    return this.client.checkout.addLineItems(checkoutId, lineItemsToAdd)
      .then(checkout => this.setState({ checkout, cartRequest: false }))
  }

  _updateQuantity = (lineItemId, quantity) => {
    const checkoutId = this.state.checkout.id
    const lineItemsToUpdate = [{id: lineItemId, quantity: parseInt(quantity, 10)}]
    this.setState({ cartRequest: true })

    return this.client.checkout.updateLineItems(checkoutId, lineItemsToUpdate)
      .then(checkout => this.setState({ checkout, cartRequest: false }))
  }

  _removeLineItem = (lineItemId) => {
    const checkoutId = this.state.checkout.id
    this.setState({ cartRequest: true })

    return this.client.checkout.removeLineItems(checkoutId, [lineItemId])
      .then(checkout => this.setState({ checkout, cartRequest: false }))
  }

  render() {
    let { location, children } = this.props

    children = React.Children.map(children, (child) =>
      React.cloneElement(child, {
        addVariantToCart : this._addVariantToCart,
        updateQuantity : this._updateQuantity,
        removeLineItem : this._removeLineItem,
        layoutState: this.state,
      })
    )

    return (
      <>
        <Header location={location} checkout={this.state.checkout} />
        <main>{ children }</main>
        <Footer location={location} />
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
